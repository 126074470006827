import { useEffect, useState } from 'react';
 
import './Main.scss';
const Main = () => {
    const [state, setstate] = useState<any>()
    useEffect(() => {
        (async () => {
            const d = await fetch('./data.json',{ cache: 'no-cache'})
            const dd = await d.json();
            setstate(dd)
        })()

    }, [])

    const onLink=(ev:any)=>{
        // console.log(ev.url)
        window.location.href=ev.url
    }
    return (<div className="main">
        <div className="header">
            <div className="logo"></div>
        </div>
        <div className="content">
            <ul>

                {state ? state.data.map((ev: any, i: number) =>
                    <li key={i}>
                        <div className="item"  >
                            <div className={'pic pic'+i} onClick={()=>onLink(ev)}>
                                <img src={ ev.icon} alt="" style={{ width: '50px', height: '50px' }} />
                            </div>
                            <h2>{ev.title}</h2>
                            <p> {ev.text}</p>
                            <div className="link" onClick={()=>onLink(ev)}>进入系统</div>
                        </div>
                    </li>)
                    : ""
                }
            </ul>
        </div>
        <div className="footer">{state?.footer} <a  target="_blank" className="record" href='https://beian.miit.gov.cn'>闽ICP备18020626号-4</a>
                <a target="_blank" className="record" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010202000700">
                    <img className="record_img" src="./pics/beian.png" />闽公网安备 35010202000700号
                </a>
        </div>


    </div>)
}


export default Main

