import React from 'react';
import Main from './views/main/Main'
import './App.scss';

function App() {
  return (
    <div className="App">
       <Main/>
    </div>
  );
}

export default App;
